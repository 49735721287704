export const autotrophyGoodsTableConf = [
	{
		label: '商品图片',
		prop: 'pict_url',
		width: 200
	},
	{
		label: '商品信息',
		prop: 'title',
		width: 200
	},
	{
		label: '商品类目',
		prop: 'name'
	},
	{
		label: '店铺名称',
		prop: 'nick'
	},
	{
		label: '直播到手价',
		prop: 'zb_price'
	},
	{
		label: '规格',
		prop: 'skuItem'
	},
	{
		label: '库存',
		prop: 'sku'
	},
	{
		label: '创建时间',
		prop: 'createtime'
	},
	{
		label: '优惠信息',
		prop: 'yhInfo'
	},
	{
		label: '卖点',
		prop: 'content'
	},
	{
		label: '状态',
		prop: 'statusInfo'
	},
	{
		label: '操作',
		prop: 'operation',
		width: 200
	}
]

export const searchFormList = [
	{
		label: '商品名称',
		prop: 'title',
		type: 'input'
	},
	{
		label: '店铺名称',
		prop: 'shop_name',
		type: 'input'
	},
	{
		label: '商品状态',
		prop: 'status',
		type: 'select',
		options: [
			{
				label: '全部',
				value: 0
			},
			{
				label: '上架',
				value: 1
			},
			{
				label: '下架',
				value: 2
			}
		]
	},
]

export const goodsDetailFormList2 = [
	{
		label: '直播信息',
		prop: '1',
		type: 'title',
		required: false,
		show: true
	},
	{
		label: '意向佣金比例',
		type: 'input',
		prop: 'yx_rate',
		append: '%',
		required: false,
		show: true
	},
	{
		label: '优惠方式',
		type: 'select',
		prop: 'yh_type',
		options: [
			{
				name: '无',
				id: 1
			},
			{
				name: '拍下立减',
				id: 2
			},
			{
				name: '详情页领券',
				id: 3
			},
			{
				name: '联系客服领取',
				id: 4
			},
			{
				name: '其他',
				id: 5
			},
			{
				name: '直接改价',
				id: 6
			}
		],
		required: false,
		show: true
	},
	{
		label: '优惠',
		prop: '8',
		type: 'solt',
		required: false,
		show: true
	},
	{
		label: '报名规格',
		prop: '5',
		type: 'solt',
		required: false,
		show: true
	},
	{
		label: '基本信息',
		type: 'title',
		prop: '24',
	},
	{
		label: '商品链接',
		type: 'input',
		prop: 'item_url',
	},
	{
		label: '是否有保质期',
		type: 'select',
		prop: 'is_day',
		options: [
			{
				name: '是',
				id: 1
			},
			{
				name: '否',
				id: 2
			}
		],
		required: false,
		show: true
	},
	{
		label: '生产日期',
		type: 'date',
		prop: 'sc_day',
		required: false,
		show: true
	},
	{
		label: '发货时长',
		type: 'input',
		prop: 'send_time',
		append: '小时',
		required: false,
		show: true
	},
	{
		label: '快递公司信息',
		type: 'input',
		prop: 'kd',
		required: false,
		show: true
	},
	{
		label: '不发件地区',
		type: 'input',
		prop: 'bfj',
		required: false,
		show: true
	},
	{
		label: '不包邮地区',
		type: 'input',
		prop: 'bby',
		required: false,
		show: true
	},
	{
		label: '最大客服数',
		type: 'input',
		prop: 'kf',
		required: false,
		show: true
	},
	{
		label: '库存',
		type: 'input',
		prop: 'sku',
		required: false,
		show: true
	},
	{
		label: '商品卖点',
		type: 'solt',
		prop: '33',
		required: false,
		show: true
	},

	// {
	// 	label: '联系信息',
	// 	type: 'title',
	// 	disabled: true,
	// 	prop: '30',
	// 	required: false,
	// 	show: true
	// },
	// {
	// 	label: '选择联系人',
	// 	type: 'input',
	// 	prop: '301',
	// 	required: false,
	// 	show: true
	// },
	// {
	// 	label: '选择联电话',
	// 	type: 'input',
	// 	prop: '3013',
	// 	required: false,
	// 	show: true
	// },
	// {
	// 	label: '选择联微信',
	// 	type: 'input',
	// 	prop: '3014',
	// 	required: false,
	// 	show: true
	// },
	// {
	// 	label: '认证（可上传授权书，专利书等）',
	// 	type: 'title',
	// 	prop: '32',
	// 	required: false,
	// 	show: true
	// },
	// {
	// 	label: '正品认证',
	// 	type: 'solt',
	// 	prop: '36',
	// 	required: false,
	// 	show: true
	// }
]