<template>
	<div class="configuration_view">
		<div class="flex_r f_jc_b">
			<div class="flex_r">
				<div class="img ml20 mr10">
					<img width="146" height="146" :src="data.pict_url" alt="">
				</div>
				<div class="goods_info flex1">
					<div class="flex_r">
						<div class="flex_r f_ai_c">
							<div class="mt8 mb8 mb10 ml20 mr20 fs16 fw_b">{{data.title}}</div>
							<el-tag>{{data.name}}</el-tag>
						</div>
						<el-button class="ml50" type="primary" @click="disabled = false;">编辑商品</el-button>
					</div>
					<div class="ml10">
						<span>商品类目：</span>
						<el-select :disabled="disabled" class="mr20" v-model="data.cid" >
							<el-option :label="c.name" :value="c.id" v-for="c in category" :key="c.index"></el-option>
						</el-select>
						<span>原价：</span>
						<el-input :disabled="disabled" class="w_50" v-model="data.s_price"></el-input>
						<span class="pl10 pr10">~</span>
						<el-input :disabled="disabled" class="w_50" v-model="data.e_price"></el-input>
					</div>
					<div  class="ml10 mt20">
						<span>直播价：</span>
						<el-input :disabled="disabled" class="w_50" v-model="data.zb_price"></el-input>
						<span class="pl10 pr10">~</span>
						<el-input :disabled="disabled" class="w_50" v-model="data.zb_e_price"></el-input>
					</div>
					<!-- <div class="w_100 pt8 pb8 bg_red brs_4 br_1_eee tc ml20 cr_fff">查看价格走势</div> -->
<!--					<div class="specification ml20 flex_r">-->
<!--						<ul>-->
<!--							<li class="mb10">-->
<!--								<span class="mr20">尺码</span>-->
<!--								<el-tag class="mr20" size="large" effect="plain" type="info">S</el-tag>-->
<!--								<el-tag class="mr20" size="large"  effect="plain" type="info">M</el-tag>-->
<!--								<el-tag class="mr20" size="large"  effect="plain" type="info">L</el-tag>-->
<!--							</li>-->
<!--							<li class="mb10">-->
<!--								<span class="mr20">颜色分类</span>-->
<!--								<el-tag size="large"  effect="plain" type="info">香槟色</el-tag>-->
<!--							</li>-->
<!--						</ul>-->
<!--					</div>-->
				</div>
			</div>
			<div class="pl20 pr20 br_l_1_eee">
<!--				<img width="80" height="80" src="//img.alicdn.com/imgextra//de/00/T1.8ckFodcXXb1upjX.jpg" alt="">-->
				<div class="tc mt10"> {{data.nick}} </div>
			</div>
		</div>
		<div class="mt40">
			<el-tabs v-model="activeName">
				<el-tab-pane label="商品信息" name="1">
					<e-form ref="formRef" v-on:selectChange="selectChange" :disabled="disabled" class="w75" labelWidth="120px" :formConfList="goodsDetailFormList2">
						<template #5>
							<div>
								<el-tag class="mr10" closable effect="plain" @close="handleClose(index)" v-for="(sku,index) in skus" :key="sku.index">{{sku.attr_name}}-{{sku.price}}</el-tag>
								<!--						<el-tag class="cur_p" type="danger" @click="$refs.dialogRef.handleOpen()">自定义报名规格</el-tag>-->
							</div>
						</template>
						<template #8>
							<div v-if="yh">
								<el-input placeholder="优惠额度" v-model="data.yh_price"></el-input>
							</div>
						</template>
						<template #33>
							<editor ref="editorRef" />
						</template>
<!--						<template #36>-->
<!--							<el-upload-->
<!--							class="avatar-uploader"-->
<!--							action="https://jsonplaceholder.typicode.com/posts/"-->
<!--							:show-file-list="false"-->
<!--							:on-success="handleAvatarSuccess"-->
<!--							:before-upload="beforeAvatarUpload">-->
<!--							<img v-if="imageUrl" :src="imageUrl" class="avatar">-->
<!--							<i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--							</el-upload>-->
<!--						</template>-->

					</e-form>
					<div class="tr mt40 mr30">
						<el-button type="primary" size="large" @click="confirm">提交</el-button>
					</div>
				</el-tab-pane>
<!--				<el-tab-pane label="报名信息" name="2">-->
<!--					<div>-->
<!--						<div class="table_title flex_r bg_table mb10">-->
<!--							<span>商品信息</span>-->
<!--							<span>优惠信息</span>-->
<!--							<span>福利</span>-->
<!--							<span>直播数据</span>-->
<!--							<span>操作</span>-->
<!--						</div>-->
<!--						<div class="br_1_eee">-->
<!--							<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">-->
<!--								<div>-->
<!--									<span class="pr20">报名日期： 2021-05-24 05:46:05</span>-->
<!--									<span class="pr20">意向排期：xxxx</span>-->
<!--									<span>意向主播： xxx</span>-->
<!--								</div>-->
<!--								<div>-->
<!--									<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">-->
<!--									<span>INNSO悦赏旗舰店</span>-->
<!--								</div>-->
<!--								<span>主播：xxx</span>-->
<!--							</div>-->
<!--							<ul class="item_ul flex_r f_ai_c f_jc_b fs12">-->
<!--								<li class="br_r_1_eee flex_r">-->
<!--									<div class="br_r_1_eee pr10 mr10">-->
<!--										<img height="80" width="80" src="https://filenew.lxshuju.com/000000/zanwutupian.png" alt="">-->
<!--										<el-tag>女装</el-tag>-->
<!--									</div>-->
<!--									<div class="flex_c f_jc_b">-->
<!--										<el-link class="lh_12" type="primary">零限提臀裤健身高腰提臀显瘦蜜桃翘臀塑形瑜伽紧身裤女外穿弹力裤</el-link>-->
<!--										<div>-->
<!--											<span>在售价：</span>-->
<!--											<span>3999</span>-->
<!--										</div>-->
<!--										<div>-->
<!--											<span>直播价：</span>-->
<!--											<span>3999</span>-->
<!--										</div>-->
<!--										<div class="flex_r">-->
<!--											<span class="w_50">规格：</span>-->
<!--											<span>尺码&#45;&#45;S;颜色分类&#45;&#45;香槟色 ¥1529、尺码&#45;&#45;M;颜色分类&#45;&#45;香槟色 ¥1529、尺码&#45;&#45;L;颜色分类&#45;&#45;香槟色 ¥1529</span>-->
<!--										</div>-->
<!--									</div>-->
<!--								</li>-->
<!--								<li class="br_r_1_eee flex_c f_jc_b">-->
<!--									<div>-->
<!--										<span class="cr_999">原价(划线价)：</span>-->
<!--										<span>¥0~78.00</span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">在售价：</span>-->
<!--										<span>¥0~78.00</span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">-联系客服领取：</span>-->
<!--										<span>¥0~78.00</span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">=直播到手价：</span>-->
<!--										<span>¥0~78.00</span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">历史最低价：</span>-->
<!--										<span class="cr_ff0">&#45;&#45;</span>-->
<!--									</div>-->
<!--								</li>-->
<!--								<li class="br_r_1_eee">-->
<!--									<div class="pb10">赠品：&#45;&#45;</div>-->
<!--									<div>抽奖：&#45;&#45;</div>-->
<!--								</li>-->
<!--								<li class="br_r_1_eee flex_c f_jc_b">-->
<!--									<div>-->
<!--										<span class="cr_999">状态：</span>-->
<!--										<span>待审核</span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">活动名称：</span>-->
<!--										<span>&#45;&#45;</span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">活动时间：</span>-->
<!--										<span></span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">销量：</span>-->
<!--										<span></span>-->
<!--									</div>-->
<!--									<div>-->
<!--										<span class="cr_999">销售额</span>-->
<!--										<span class="cr_ff0">&#45;&#45;</span>-->
<!--									</div>-->
<!--								</li>-->
<!--								<li class="br_r_1_eee flex_c f_jc_a f_ai_c">-->
<!--									<span class="cur_p" @click="$router.push('/signupDetail')">报名详情</span>-->
<!--								</li>-->
<!--							</ul>-->
<!--						</div>-->
<!--						<el-pagination-->
<!--							class="tr"-->
<!--							:small="true"-->
<!--							:current-page.sync="currentPage"-->
<!--							:page-size="pageSize"-->
<!--							:total="total">-->
<!--						</el-pagination>-->
<!--					</div>-->
<!--				</el-tab-pane>-->
			</el-tabs>
		</div>
		<e-dialog title="添加规格" ref="dialogRef">
			<el-form label-width="auto">
				<el-form-item label="名称">
					<el-input></el-input>
				</el-form-item>
				<el-form-item label="价格">
					<el-input></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
		<e-dialog title="添加联系人" ref="dialogUserRef">
			<el-form label-width="auto">
				<el-form-item label="联系人">
					<el-input></el-input>
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input></el-input>
				</el-form-item>
				<el-form-item label="联系地址">
					<el-input></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-radio-group v-model="radio">
						<el-radio :label="3">微信</el-radio>
						<el-radio :label="6">钉钉</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="账号">
					<el-input></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
	</div>
</template>

<script>
import {goodsDetailFormList2} from '@data/autotrophyGoods'
import Editor from '../../components/editor.vue'
export default {
	name: 'GoodsDetail',
	data() {
		return {
			goodsDetailFormList2,
			activeName: '1',
			disabled: true,
			data: {},
			yh: false,
			ys:false,
			id: '',
			goods: {},
			skus: [],
			category: [],
		}
	},
	components: {
		Editor
	},
	methods: {
		selectChange(e){
			let that = this
			console.log(e.item.prop)
			switch (e.item.prop) {
				case 'ys':
					switch (e.value) {
						case 1:
							that.ys = true
							break;
						case 2:
							that.ys = false
							break;
						default :
							that.ys = false
							break;
					}
					break;
				case 'yh_type':
					switch (e.value) {
						case 1:
							that.yh = false
							break;
						case 2:
							that.yh = true
							break;
						case 4:
							that.yh = true
							break;
						case 3:
							that.yh = true
							break;
						default :
							that.yh = false
							break;
					}
					break;
			}

		},
		handleClose(index){
			this.skus.splice(index, 1);
		},
		handleClick() {},
		confirm(){
			let that = this
			let formData = this.$refs.formRef.formData
			if(JSON.stringify(this.data) == '{}'){
				that.$message.error('请完善商品信息');
				return false
			}
			if(formData.yx_rate == undefined || formData.yx_rate == ''){
				that.$message.error('请输入意向佣金');
				return false
			}
			if(this.data.cid == undefined || this.data.cid == ''){
				that.$message.error('请选择商品类目');
				return false
			}
			if(this.data.s_price == undefined || this.data.s_price == ''){
				that.$message.error('请输入最低在售价格');
				return false
			}
			if(this.data.e_price == undefined || this.data.e_price == ''){
				that.$message.error('请输入最高在售价格');
				return false
			}
			if(this.data.zb_price == undefined || this.data.zb_price == ''){
				that.$message.error('请输入最低直播价');
				return false
			}
			if(this.data.zb_e_price == undefined || this.data.zb_e_price == ''){
				that.$message.error('请输入最高直播价');
				return false
			}
			if(this.$refs.editorRef.content == undefined || this.$refs.editorRef.content == ''){
				that.$message.error('请输入卖点');
				return false
			}
			this.post('?server=live.anchor.goods.edit.push.handle.post',{
				id:this.data.id,
				yx_rate:formData.yx_rate,
				cid:this.data.cid,
				s_price: this.data.s_price,
				e_price: this.data.e_price,
				zb_price: this.data.zb_price,
				zb_e_price: this.data.zb_e_price,
				sku:formData.sku,
				sc_day: formData.sc_day,
				is_day: formData.is_day,
				send_time: formData.send_time,
				bby: formData.bby,
				kd: formData.kd,
				yh_type: formData.yh_type,
				lj: formData.yh_price,
				lq_lj: formData.yh_price,
				kf_lj: formData.yh_price,
				content: this.$refs.editorRef.content,
				kf: formData.kf,
				bfj:formData.bfj}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$emit('success')
				that.$refs.dialogRef.handleClose()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetDetail(){
			let that = this
			this.get('?server=live.goods.item.get',{id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.data = response.data.goods
				switch (response.data.goods.yh_type) {
					case 1:
						break;
					case 2:
						that.data.yh_price = response.data.goods.lj
						that.yh = true
						break;
					case 4:
						that.data.yh_price = response.data.goods.kf_lj
						that.yh = true
						break;
					case 3:
						that.data.yh_price = response.data.goods.lq_lj
						that.yh = true
						break;
				}
				let len = response.data.sku.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.skus.push(response.data.sku[i])
				}
				that.category = response.data.category
				that.$refs.formRef.formData = response.data.goods
				that.$refs.editorRef.content = response.data.goods.content
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.id = this.$route.query.id

			if(!this.id){
				this.$message.error('请求非法')
			} else {
				this.GetDetail()
			}
		});

	},
}
</script>

<style lang="scss" scoped>
.configuration_view {
	width: 1270px;
	margin: 60px auto 0 auto;
}
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

.table_title {
	span {
		line-height: 40px;
		width: 20%;
		text-align: center;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 140px;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
</style>